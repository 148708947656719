<!-- 监控总览 -->
<template>
  <div class="video">
    <div class="site_list">
      <el-input placeholder="请输入站点名称" v-model="filterText"> </el-input>

      <div
        class="item"
        :class="{ active: isActive == index }"
        v-for="(item, index) in siteList"
        :key="index"
        @click="siteClick(index, item.id)"
      >
        <span>{{ index + 1 }}</span>
        <span>{{ item.siteName }}</span>
      </div>
    </div>
    <div
      class="videoCenter"
      @mouseover="videoMouseover"
      @mouseout="videoMousemove"
    >
      <div class="topline ln2"></div>
      <!-- <div class="update">刷新倒计时:</div> -->
      <div class="oneList" v-show="!isAll">
        <!-- <div class="topline ln2"></div> -->
        <div style="width: 100%; height: 100%; position: relative">
          <iframe
            frameborder="0"
            scrolling="no"
            :src="`https://open.ys7.com/ezopen/h5/iframe_se?url=${oneVideo.liveAddress}&accessToken=${oneVideo.accessToken}&autoplay=1`"
            width="100%"
            height="100%"
            id="ysOpenDevice"
            allowfullscreen
          ></iframe>

          <div class="huchu" @click="getBtns(oneVideo)">
            <img src="../../assets/png/huchu.png" alt />
          </div>
          <div
            class="newBtnBox"
            v-if="showId == oneVideo.id ? !btnShow : btnShow"
          >
            <div
              class="closeIcon"
              title="关闭"
              @click="getBtns(oneVideo)"
            ></div>
            <div
              class="yuanjiao"
              title="远焦"
              @click="changeVideo(11, oneVideo)"
            ></div>
            <div
              class="jinjiao"
              title="近焦"
              @click="changeVideo(10, oneVideo)"
            ></div>
            <div
              class="suoxiao"
              title="缩小"
              @click="changeVideo(9, oneVideo)"
            ></div>
            <div
              class="fangda"
              title="放大"
              @click="changeVideo(8, oneVideo)"
            ></div>
            <div class="btn-box">
              <div
                class="shang"
                title="上"
                @click="changeVideo(0, oneVideo)"
              ></div>
              <div
                class="xia"
                title="下"
                @click="changeVideo(1, oneVideo)"
              ></div>
              <div
                class="zuo"
                title="左"
                @click="changeVideo(2, oneVideo)"
              ></div>
              <div
                class="you"
                title="右"
                @click="changeVideo(3, oneVideo)"
              ></div>
              <div
                class="zanting"
                title="暂停"
                :class="{ zantingActive: isTrue }"
                @click="changeVideoStop(-1, oneVideo)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 98%;
          z-index: 9;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        v-if="valiList.total == 0"
        @mouseover="videoMouseover"
        @mouseout="videoMousemove"
      >
        <img
          src="../../assets/images/index/video.png"
          style="
            width: calc(330 / 1920 * 100vw);
            height: calc(329 / 1080 * 100vh);
          "
        />
      </div>
      <div
        style="position: absolute;
          top: 50%;
          width: 65%;
          justify-content: space-between;
          display: flex;
          z-index: 9;
        "
        v-show="videoPage && valiList.total > 6"
      >
        <img
          src="../../assets/images/ico/left.png"
          alt=""
          @click="videoTabs(1)"
        />
        <img
          src="../../assets/images/ico/right.png"
          alt=""
          @click="videoTabs(2)"
        />
      </div>
      <div class="videoList" v-if="isAll && valiList.total != 0">
        <!-- <iframe style="width: 100%;height: 100%;"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(data.list))}&num=${data.list.length}&width=400&height=400`"
                      frameborder="0"></iframe> -->
        <div class="listItem" :id="'id'+index" v-for="(item, index) in data.list" :key="index">
          <iframe style="width: 100%;height: 100%;" allow="camera *; microphone *;" :allowfullscreen="true"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(item))}&num=${1}&width=${400/1920*windowWidth}&height=${400/1080*(windowHeight)}`"
                      frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import elementResizeDetectorMaker from "element-resize-detector"; //监听dom元素宽高变化的插件
// import survebtn from "./surveillancebtn";
import { qdllj } from "../../assets/js/html";
import { login } from "../../api/login";
import BtnVideo from "../../components/BtnVideo/index.vue";

export default {
  name: "PointVideos",
  components: {
    BtnVideo,
  },
  data() {
    return {
      isTrue: false,
      roleId: localStorage.getItem("id"),
      speed: 2,
      oneVideo: {},
      index: 0,
      btnShow: false,
      accessToken: "",
      deviceserial: "",
      selectId: "",
      isAll: true,
      showId: "",
      data: {
        pagesize: 999,
        currpage: 1,
        list: [],
      },
      playerInstance:null,
      pointName: "",
      oneList: [],
      valiPage: [],
      valiList: [],
      waterEquipmentList: [],
      page: 1,
      size: 10,
      filterText: "",
      siteList: [],
      isActive: 0,
      siteId: "",
      page: 1,
      videoPage: false,
      bsTabsShow:true,
      windowWidth: 1920, //获取屏幕宽度,
      windowHeight: 1080, //获取屏幕高度,
    };
  },
  created() {
    this.getSiteList();
  },
  watch: {
    // 监听设备宽高
    windowWidth(newW, oldW) {
      let that = this;
      console.log("实时屏幕高度：", newW, that.windowWidth);
    },
    windowHeight(newH, oldH) {
      let that = this;
      console.log("实时屏幕高度：", newH, that.windowHeight);
    },
  },
  mounted() {
    this.windowWidth=document.documentElement.clientWidth
    this.windowHeight=document.documentElement.clientHeight
  },
  methods: {
    // 鼠标移入vide容器判断是否显示分页切换
    videoMouseover() {
      this.videoPage = true;
    },
    videoMousemove() {
      this.videoPage = false;
    },
    siteClick(index, id) {
      this.isActive = index;
      this.siteId = id;
      this.page = 1;
      this.bsTabsShow=true
      this.getVideo(id);
    },
    listItemClick(val) {
      this.$router.push({
        name: "videodetailslist",
        params: { siteId: val.siteId },
      });
    },
    videoItemClick(index,type) {
      console.log('index===',index);
      this.bsTabsShow=!this.bsTabsShow
      let videoContent=document.querySelector('#id'+index)
      let videoIfame=document.querySelector('#EZUIKitPlayer-video'+index)
      videoIfame.style.width='100%'
      videoIfame.style.height='100%'
      if(type==1){
      videoContent.style.width='98%'
      videoContent.style.height='99%'
      videoContent.style.position='absolute';
      videoContent.style.zIndex='99999'
      }else{
videoContent.style.width='33.3%'
      videoContent.style.height='48.5%'
      videoContent.style.position='';
      videoContent.style.zIndex=''
      }

    },
    // 查询节制闸列表
    getSiteList() {
      this.$get("/site/findList").then((res) => {
        this.siteList = res.data;
        this.siteId = res.data[0].id;
        this.getVideo(this.siteId);
      });
    },
    // 视频分页
    videoTabs(index) {
      if (index == 1 && this.valiList.prePage > 0) {
        this.page--;
      } else if (index == 2 && this.valiList.nextPage > 0) {
        this.page++;
      }
      this.getVideo(this.siteId);
    },
    // 查询视频资源
    getVideo(siteId) {
      this.data.list=[]
      let size = 6;
      this.$get(`/video/pageGeneral/${this.page}/${size}`, {
        siteId: siteId,
      }).then((res) => {
        this.valiList = res.data;
        // this.valiList.list.forEach((item) => {
        //   item.indexId = item.id;
        //   this.accessToken = item.accessToken;
        //   this.deviceserial = item.cameraDeviceNumber;
        //   // `https://open.ys7.com/ezopen/h5/iframe_se?url=${item.videoAddress}&accessToken=${item.accessToken}&autoplay=1`
        //   item.endTime = `https://open.ys7.com/ezopen/h5/iframe?url=${item.videoAddress}&accessToken=${item.accessToken}&autoplay=1`;
        // });
        this.data.list = res.data.list;
        setTimeout(() => {
          res.data.list.forEach((item, index) => {
            this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "video"+index,
              // header: ["capturePicture", "save"],
              // footer: ["hd", "fullScreen"],
              accessToken: item.accessToken,
              url: item.videoAddress,
              template: "theme",
              // audio: 0,
            });
          });
        }, 1000);
      });
    },
    // 视频切换
    getBtns(item) {
      console.log(item.id);
      this.showId == item.id ? (this.showId = "") : (this.showId = item.id);
      // console.log(this.showId)
      // this.btnShow = true;
    },
    // closedBtns (item) {
    //   this.showId = ''
    // },
    changeVideo(direction, item) {
      this.getVideoAbility(direction, item, 1);
    },
    // 视频停止
    changeVideoStop(speed, item) {
      this.isTrue = !this.isTrue;
      this.getVideoAbility(0, item, -1);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .panel-top {
  display: none !important;
}

.active {
  background-color: rgba(155, 188, 245, 0.4);
  color: #4579bc !important;
  font-weight: 600;
}

.video {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .topline {
    width: 100%;
    height: 5px;
    //background: #61a4e4;
    border-radius: 5px;
  }

  .ln2 {
    //background: #61a4e4;
    height: 5px;
    margin-bottom: 10px;
  }

  .bod {
    width: 10px !important;
    height: 10px !important;
    background: #097fe1;
    border-radius: 50%;
    margin-top: 16px;
    margin-right: 10px;
  }

  .list_left {
    width: 240px;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-right: 15px;
    border-radius: 8px;

    .item {
      box-sizing: border-box;

      .top {
        display: flex;
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid #e9e9e9;
        box-sizing: border-box;
        padding-left: 15px;

        .numberList {
          margin-left: 44%;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2c2c2c;
        }
      }

      h3 {
        // height: 44px;
        text-align: left;
        cursor: pointer;
        font-size: 16px;
      }

      .listul {
        height: 760px;
        overflow-y: auto;
        padding: 0px 18px 20px 20px;
      }

      ul > li {
        list-style: none;
        text-align: left;
        padding: 10px 0;
        padding-left: 25px;
        border-bottom: 1px solid #e9e9e9;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2c2c2c;
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  .site_list {
    width: 280px;
    height: 95.5%;
    margin-right: 20px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    .item {
      cursor: pointer;
      color: #666666;
      margin-top: 10px;
      font-size: 16px;
      padding: 5px 10px;
      span:nth-child(1) {
        display: inline-block;
        width: 20px;
        margin-right: 10px;
      }
    }
  }
  .videoCenter {
    overflow: auto;
    flex: 1;
    height: 98%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 1px 21px 0px rgba(38, 46, 45, 0.06);
    opacity: 0.8;
    border-radius: 9px;

    .update {
      width: 100%;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 10px;

      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #eb6100;
    }

    .videoList {
      width: 100%;
      height: 98%;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding-right: 15px;
      // background-color: aqua;
      align-content: flex-start;
      // justify-content: space-between;
      position: relative;
      .video_name{
        padding:5px 1%;
        position: absolute;
        bottom: 10px;
        z-index: 999;
        float: right;
      }
      .video_enlarge{
        position: absolute;
        top: 12px;
        right: 20px;
        cursor: pointer;
      }
      .listItem {
        width: 33.3%;
        height: 48.5%;
        position: relative;
        .icoVideo {
          position: absolute;
          bottom: 30px;
          right: 130px;
          z-index: 9999;
          ::v-deep .btn-control{
            position: absolute;
            bottom: 20px;
            right: -125px;
          }
        }
        .btnBox {
          position: absolute;
          top: 50%;
          right: 0;
          width: 150px;
          height: 80%;
          padding: 26px;
          background: rgba(5, 29, 70, 0.2);
          // opacity: 0.2;
          transform: translateY(-50%);
          border-radius: 8px;

          .iicon {
            position: absolute;
            width: 26px;
            height: 26px;
            top: 0;
            left: 0;
            cursor: pointer;

            img {
              width: 100%;
            }
          }

          .jujiao {
            width: 150px;
            height: 60px;
            margin-top: 8px;
            // background-color: aquamarine;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .yuanjiao {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }

            .jinjiao {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }

          .yuan {
            width: 140px;
            height: 140px;
            //background: url("../../../assets/png/dayuan.png") center no-repeat;
            background-size: cover;
            background: url("../../assets/png/dayuan.png") center no-repeat;
            position: relative;

            .zanting {
              position: absolute;
              width: 49px;
              height: 49px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;

              img {
                width: 100%;
              }
            }

            .shang {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              top: 10px;
              left: 50%;
              transform: translate(-50%, 0);

              img {
                width: 100%;
              }
            }

            .xia {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              bottom: 10px;
              left: 50%;
              transform: translate(-50%, 0);

              img {
                width: 100%;
              }
            }

            .zuo {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              top: 50%;
              left: 10px;
              transform: translate(0, -50%);

              img {
                width: 100%;
              }
            }

            .you {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              top: 50%;
              right: 10px;
              transform: translate(0, -50%);

              img {
                width: 100%;
              }
            }
          }

          .fangdasuoxiao {
            width: 150px;
            height: 60px;
            // background-color: rgb(73, 207, 248);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .fangda {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 30px;
                height: 30px;
              }
            }

            .suoxiao {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .huchu {
          position: absolute;
          width: 25px; /*no*/
          height: 25px; /*no*/
          right: 48px; /*no*/
          margin-left: -10px;
          bottom: 11px; /*no*/
          cursor: pointer;
          background: #3d3d3d;

          img {
            width: 17px; /*no*/
            height: 17px; /*no*/
            padding-top: 4px;
          }
        }
      }
    }

    .oneList {
      margin: 1% 1% 0;
      width: 98%;
      height: 96%;
      padding-bottom: 4px;
      background-color: #333;
      position: relative;

      .huchu {
        position: absolute;
        width: 25px; /*no*/
        height: 25px; /*no*/
        right: 48px; /*no*/
        margin-left: -10px;
        bottom: 11px; /*no*/
        cursor: pointer;
        background: #3d3d3d;

        img {
          /*width: 100%;*/
          width: 17px;
          height: 17px;
          text-align: center;
          padding-top: 3px;
        }
      }
    }
  }

  .videoLeft,
  .videoRight {
    width: 285px;
    height: 100%;
    background-color: #ccc;
  }

  .water_equipment {
    width: 100%;

    .equipment_item {
      width: 100%;
      height: 68px;
      padding: 10px;
      background-color: #fff;
      cursor: pointer;

      &:hover {
        background-color: rgb(152, 152, 243);
      }
    }
  }
}

/deep/ .el-pagination {
  margin-top: -70px;

  /deep/ .number,
  .btn-prev,
  .btn-next {
    background: #ffffff !important;
    border: 1px solid #4994fb;
    border-radius: 3px;
  }

  /deep/ .active {
    background: #4994fb !important;
    border-radius: 3px;
    // background-color: #4994FB;
  }
}

/deep/ .el-pagination.is-background .el-pager li  {
  border: 1px solid #4994fb;
}
.zoomOut{
  width: 98% !important;
  height: 99% !important;
  position: absolute !important;
  z-index: 99999;
}
</style>
