import "../../views/simpleness/js/index.scss";

export function networkingMode(val) {
	let str = "";
	switch (val) {
		case 0:
			str = "Net";
			break;
		case 1:
			str = "Wifi";
			break;
		case 2:
			str = "NB-IoT";
			break;
		case 3:
			str = "2G";
			break;
		case 4:
			str = "3G";
			break;
		case 5:
			str = "4G";
			break;
		default:
			str = "";
			break;
	}
	return str;
}

export const llj = data => {
	if (data) {
		return `
    <div class="llj">
        <div class="lljBox">
            <div class="lljBox_title">
                <h3 class="name">${data.name}</h3>
                <div class="index">编号：${data.index}</div>
            </div>
            <div class="lljMain">
                <ul class="lljMain_list">
                    <li class="lljMain_item"><a class="info_a">信号强度</a>：<span>${data.clientRssi ||
		"--"}</span></li>
                    <li class="lljMain_item"><a class="info_a">联网方式</a>：<span>${
			data.networkingMode || "4"}G</span></li>
                    <li class="lljMain_item"><a class="info_a" style='font-weight: bold;color: #11B36C'>瞬时流量：</a><span style='color: #11B36C'>${data.insFlow ||
		"0"}m³/h</span></li>
                    <li class="lljMain_item"><a class="info_a">累计水量</a>：<span>${data.sumFlow ||
		"0"}m³</span></li>
                      <li class="lljMain_item" style='font-weight: bold;color: #11B36C'><a class="info_a" style='font-weight: bold;color: #11B36C'>水位</a>：<span style='color: #11B36C'>${data.level ||
		"0"}m</span></li>
                    <li class="lljMain_item"><a class="info_a">流速</a>：<span>${data.speed ||
		"0"}m/s</span></li>
                    <li class="lljMain_item"><a class="info_a">灌溉面积</a>：<span>${data.measureArea || '--'}亩</span></li>
                    <li class="lljMain_item"><a class="info_a">村庄数量</a>：<span>${data.villageNum || '--'}</span></li>
                    <li class="lljMain_item"><a class="info_a">箱门状态</a>：<span>${data.boxdoorStatus ||
		"--"}</span></li>
                    
                    <li class="lljMain_item"><a class="info_a">电池电压</a>：<span>${data.vol ||
		"0"}V</span></li>
                    <li class="lljMain_item"><a class="info_a">设备编号</a>：<span>${data.devSn ||
		"0"}</span></li>
                    <li class="lljMain_item"><a class="info_a">空高</a>：<span>${data.vacantHeight ||
		"0"}m</span></li>
                    <li class="lljMain_item"><a class="info_a">采集时间</a>：<span>${data.caijiTime ||
		"--"}</span></li>
                  
                </ul>
            </div> 
            <div class="img">
               
            </div>
           
        </div>
    </div>
  `;
//   <div class="title_img" οnclick="closeBtn" >
//   <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
// </div>
	} else {
		return `
    <div class="llj">
        <div class="lljBox">
            <div class="lljBox_title">
                <h3 class="name">--</h3>
                <h3 class="time">--</h3>
            </div>
            <div class="lljMain">
                <ul class="lljMain_list">
                    <li class="lljMain_item"><a class="info_a">信号强度</a>：<span>--</span></li>
                    <li class="lljMain_item"><a class="info_a">联网方式</a>：<span>--</span></li>
                    <li class="lljMain_item" style='color: #11B36C'><a class="info_a" style='font-weight: bold;color:#11B36C'>瞬时流量</a>：<span>--m³/h</span></li>
                    <li class="lljMain_item"><a class="info_a">累计水量</a>：<span>--m³</span></li>
                    <li class="lljMain_item"><a class="info_a">风速</a>：<span>--m/s</span></li>
                    <li class="lljMain_item" style='color: #11B36C'><a class="info_a" style='font-weight: bold;color: #11B36C'>水位</a>：<span>--m</span></li>
                    <li class="lljMain_item"><a class="info_a">流速</a>：<span>--m/s</span></li>
                    <li class="lljMain_item"><a class="info_a">电池电压</a>：<span>--V</span></li>
                    <li class="lljMain_item"><a class="info_a">箱门状态</a>：<span>--</span></li>
                    <li class="lljMain_item"><a class="info_a">空高</a>：<span>--m</span></li>
                    <li class="lljMain_item"><a class="info_a">采集时间</a>：<span>--</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
		// <div class="title_img" οnclick="closeBtn" >
		//               <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
		//           </div>
	}
};

export const qdllj = data => {
	if (data) {
		// <li class="lljMain_item"><a class="info_a">经度</a>：<span>${data.lon ||
		//   ""}</span></li>
		// <li class="lljMain_item"><a class="info_a">纬度</a>：<span>${data.lat ||
		//   ""}</span></li>
		//   <li class="lljMain_item long"><a class="info_a">仪表编码</a>：<span>${data.meterId ||
		//     ""}</span></li>
		//   <li class="lljMain_item long"><a class="info_a">Imei号</a>：<span>${data.imei ||
		//     ""}</span></li>
		//   <li class="lljMain_item long"><a class="info_a">卡号</a>：<span>${data.cardId ||
		//     ""}</span></li>
		//   <li class="lljMain_item long"><a class="info_a">安装位置</a>：<span>${data.pos ||
		//     ""}</span></li>
		return `
    <div class="llj lljBoxs">
        <div class="lljBox lljBoxss">
            <div class="lljBox_title">
                <h3 class="name">${data.meterName}</h3>
                <div class="index">编号：${data.index}</div>
            </div>
            <div class="lljMain">
                <ul class="lljMain_list">
                    <li class="lljMain_item long" style="color: #04b35b;"><a class="info_a">流速</a>：<span style="color: #04b35b;">${data.flowSpeed ||
		""}(m/s)</span></li>
                      <li class="lljMain_item long" style="color: #04b35b;"><a class="info_a">水位</a>：<span style="color: #04b35b;">${data.waterLevel ||
		""}(m)</span></li>
                        <li class="lljMain_item long"><a class="info_a" style='font-weight: bold;color: #11B36C;'>瞬时流量：</a><span style='color: #11B36C'> ${Number(
			data.insFlow
		).toFixed(2) || ""}(m³/h)</span></li>
                    <li class="lljMain_item long"><a class="info_a">累计流量</a>：<span>${Number(
			data.totalFlow
		).toFixed(2) || ""}(m³)</span></li>
                    <li class="lljMain_item"><a class="info_a">灌溉面积</a>：<span>${data.measureArea || '--'}亩</span></li>
                    <li class="lljMain_item"><a class="info_a">村庄数量</a>：<span>${data.villageNum || '--'}</span></li>
                    <li class="lljMain_item long"><a class="info_a">经度</a>：<span>${data.lon ||
		""}</span></li>
                      <li class="lljMain_item long"><a class="info_a">纬度</a>：<span>${data.lat ||
		""}</span></li>
                    <li class="lljMain_item long"><a class="info_a">采集时间</a>：<span>${data.time ||
		""}</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
		// <div class="title_img" οnclick="closeBtn" >
		//               <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
		//           </div>
	} else {
		return `
    <div class="llj">
        <div class="lljBox">
            <div class="lljBox_title">
                <h3 class="name">--</h3>
            </div>
            <div class="lljMain">
                <ul class="lljMain_list">
                    <li class="lljMain_item"><a class="info_a">流速</a>：<span>--(m/s)</span></li>
                    <li class="lljMain_item"><a class="info_a">泥位</a>：<span>--(m)</span></li>
                    <li class="lljMain_item"><a class="info_a">经度</a>：<span>--</span></li>
                    <li class="lljMain_item"><a class="info_a">纬度</a>：<span>--</span></li>
                    <li class="lljMain_item"><a class="info_a" style='font-weight: bold;color:#11B36C'>瞬时流量</a>：<span>--(m³/h)</span></li>
                    <li class="lljMain_item"><a class="info_a" style='font-weight: bold;color:#11B36C'>水位</a>：<span>--(m)</span></li>
                    <li class="lljMain_item"><a class="info_a">累计流量</a>：<span>--(m³)</span></li>
                    <li class="lljMain_item long"><a class="info_a">仪表编码</a>：<span>--</span></li>
                    <li class="lljMain_item long"><a class="info_a">Imei号</a>：<span>--</span></li>
                    <li class="lljMain_item long"><a class="info_a">卡号</a>：<span>--</span></li>
                    <li class="lljMain_item long"><a class="info_a">安装位置</a>：<span>--</span></li>
                    <li class="lljMain_item long"><a class="info_a">采集时间</a>：<span>--</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
		// <div class="title_img" οnclick="closeBtn" >
		//               <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
		//           </div>
	}
};

export const bz = data => {
	if (data) {
		return `
    <div class="bz">
        <div class="bzBox">
            <div class="bzBox_title">
                <h3 class="name">${data.staName}</h3>
            </div>
            <div class="bzMain">
                <ul class="bzMain_list">
                    <li class="bzMain_item"><a class="info_a" style='font-weight: bold;color:#11B36C'>瞬时流量：</a><span style="color: #04b35b;">${data.instantaneousDelivery ||
		"0"}(m³/h)</span></li>
                      <li class="bzMain_item"><a class="info_a">累计流量</a>：<span>${data.cumulativeDischarge ||
		"0"}(m³)</span></li>
                    <li class="bzMain_item"><a class="info_a">A相电压</a>：<span>${data.aPhaseVoltage ||
		"0"}V</span></li>
                    <li class="bzMain_item"><a class="info_a">A相电流</a>：<span>${data.aPhaseCurrent ||
		"0"}A</span></li>
                    <li class="bzMain_item"><a class="info_a">B相电压</a>：<span>${data.bPhaseVoltage ||
		"0"}V</span></li>
                    <li class="bzMain_item"><a class="info_a">B相电流</a>：<span>${data.bPhaseCurrent ||
		"0"}A</span></li>
                    <li class="bzMain_item"><a class="info_a">C相电压</a>：<span>${data.cPhaseVoltage ||
		"0"}V</span></li>
                    <li class="bzMain_item"><a class="info_a">C相电流</a>：<span>${data.cPhaseCurrent ||
		"0"}A</span></li>
                      <li class="bzMain_item"><a class="info_a">总有功电能</a>：<span>${data.totalPower ||
		"0"}(kW.h)</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
		// <div class="title_img" οnclick="closeBtn" >
		//               <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
		//           </div>
	} else {
		return `
    <div class="bz">
        <div class="bzBox">
            <div class="bzBox_title">
                <h3 class="name">--</h3>
            </div>
            <div class="bzMain">
                <ul class="bzMain_list">
                    <li class="bzMain_item"><a class="info_a" style='font-weight: bold;>瞬时流量</a>：<span>--(m³/h)</span></li>
                    <li class="bzMain_item"><a class="info_a">累计流量</a>：<span>--(m³)</span></li>
                    <li class="bzMain_item"><a class="info_a">A相电压</a>：<span>--V</span></li>
                    <li class="bzMain_item"><a class="info_a">A相电流</a>：<span>--A</span></li>
                    <li class="bzMain_item"><a class="info_a">B相电压</a>：<span>--V</span></li>
                    <li class="bzMain_item"><a class="info_a">B相电流</a>：<span>--A</span></li>
                    <li class="bzMain_item"><a class="info_a">C相电压</a>：<span>--V</span></li>
                    <li class="bzMain_item"><a class="info_a">C相电流</a>：<span>--A</span></li>
                    <li class="bzMain_item"><a class="info_a">总有功电能</a>：<span>--(kW.h)</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
		// <div class="title_img" οnclick="closeBtn" >
		//               <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
		//           </div>
	}
};
export const yq = data => {
	if (data) {
		return `
    <div class="yq lljBoxs">
        <div class="yqBox lljBoxss">
            <div class="yqBox_title">
                <h3 class="name">${data.rainName||'--'}</h3>
            </div>
            <div class="yqMain">
                <ul class="yqMain_list">
                    <li class="yqMain_item long" ><a class="info_a">当前状态</a>：<span>${data.rainRunStatus==0?'正常':'下雨' ||
		"--"}</span></li>
                      <li class="yqMain_item long" ><a class="info_a">降雨开始时间</a>：<span >${data.rainStartTime ||
		"--"}</span></li>
                        <li class="yqMain_item long"><a class="info_a" >降雨停止时间：</a><span > ${
			data.rainEndTime
		 || "--"}</span></li>
                    <li class="yqMain_item long"><a class="info_a">最后一次数据报送时间</a>：<span>${
			data.dataTime || "--"}</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
	} else {
		return `
    <div class="yq lljBoxs">
        <div class="yqBox lljBoxss">
            <div class="yqBox_title">
                <h3 class="name">--</h3>
            </div>
            <div class="yqMain">
                <ul class="yqMain_list">
                    <li class="yqMain_item long"><a class="info_a">当前状态</a>：<span >--</span></li>
                      <li class="yqMain_item long" ><a class="info_a">降雨开始时间</a>：<span >--</span></li>
                        <li class="yqMain_item long"><a class="info_a" s>降雨停止时间</a>：<span > --</span></li>
                    <li class="yqMain_item long"><a class="info_a">最后一次数据报送时间</a>：<span>--</span></li>
                </ul>
            </div> 
            <div class="img">
               
            </div>
            
        </div>
    </div>
  `;
	}
};
export default {llj, qdllj, bz,yq};
